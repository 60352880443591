import React from "react";
import Hero from "../../components/common/Hero";
import { Link } from "react-router-dom";

const Track = () => {
  return (
    <div>
      <Hero title={"Track Shipment"} />
      <div className="py-10 flex items-center justify-center">
        <div className="w-full md:w-[80%] flex items-center justify-center p-5">
          <div className="w-full flex flex-col items-center max-w-lg rounded-xl shadow-md space-y-4 p-4 bg-white">
            <h1 className="text-2xl md:text-3xl font-semibold uppercase text-center border-b-2 pb-2 border-[#E11756] ">
              Track a shipment
            </h1>
            <div
              style={{
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              }}
              className="w-full my-4 px-[14px] py-[10px] flex items-center rounded-lg border border-gray-300 space-x-2"
            >
              {/* Search Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M18 18.4963L14.375 14.8713M16.3333 10.163C16.3333 13.8449 13.3486 16.8297 9.66667 16.8297C5.98477 16.8297 3 13.8449 3 10.163C3 6.48111 5.98477 3.49634 9.66667 3.49634C13.3486 3.49634 16.3333 6.48111 16.3333 10.163Z"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {/* Input field */}
              <input
                type="text"
                className="text-xs md:text-base focus-within:outline-0 focus-visible:outline-0 placeholder:text-[#667085] placeholder:text-base"
                placeholder="Package Number"
              />
            </div>
            <Link
              to=""
              className="inline-block mt-4"
              style={{ textDecoration: "none" }}
            >
              <div className="button-57" role="button">
                <span className="text">Track</span>
                <span>Track</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Track;
