import { styled } from "styled-components";
import { BsBusFrontFill, BsTrainFreightFrontFill } from "react-icons/bs";

export default function FlightSection() {
  return (
    <Main>
      <h1>
        Our <span>Services</span>
      </h1>
      <Container>
        <div>
          <figure>
            <img
              style={{
                aspectRatio: 1,
                objectFit: "cover",
              }}
              src="/assets/images/service1.webp"
              alt=""
            />
          </figure>
          <div>
            <BsBusFrontFill size={25} color={primary} />
            <h2>Shipping Freight</h2>
            <p>
              Streamline your supply chain with our Shipping Freight service.
              From small packages to large cargo, we offer efficient and
              reliable transportation solutions that connect your business with
              global opportunities.
            </p>
          </div>
        </div>
        <div>
          <div>
            <BsTrainFreightFrontFill size={25} color={primary} />
            <h2>Train Freight</h2>
            <p>
              Embrace eco-friendly and efficient transportation with our Train
              Freight service. Experience cost-effective logistics as we
              seamlessly move your goods by rail, reducing carbon footprint and
              ensuring timely deliveries.
            </p>
          </div>
          <figure>
            <img
              src="https://images.wikimotors.org/switcher-locomotive-pulling-box-cars.jpg"
              alt=""
              style={{
                aspectRatio: 1,
                objectFit: "cover",
                width: "90%",
              }}
            />
          </figure>
        </div>
      </Container>
    </Main>
  );
}

const primary = "#E11756";
// const secondary = "#FCCC0A";
// const heroText = "#BFC1C2";
// const bg = "#fff";
// const dark = "#757677";

const Main = styled.div`
  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  span {
    color: ${primary};
    font-family: Poppins;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  padding: 3rem;

  @media (max-width: 500px) {
    padding: 1.5rem;
  }

  > div {
    display: flex;
    align-items: center;

    figure {
      flex: 0.5;
      img {
        width: 100%;
      }
    }
    div {
      flex: 0.5;
      padding: 1rem;

      svg {
        margin-bottom: 1rem;
      }

      h2 {
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
      p {
        @media (max-width: 500px) {
          font-size: 0.9rem;
        }
      }
    }
    &:first-of-type {
      border-right: solid 2px ${primary};
      @media (max-width: 800px) {
        border: none;
      }
    }
  }
`;
