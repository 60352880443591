import React from "react";
import Hero from "../../components/common/Hero";
import { Link } from "react-router-dom";
import { BiSolidUser, BiSolidEnvelope } from "react-icons/bi";
const Quote = () => {
  return (
    <div>
      <Hero title={"Get a Quote"} />
      <div className="py-10 flex items-center justify-center">
        <div className="w-full md:w-[80%] flex items-center justify-center p-5">
          <div className="w-full flex flex-col items-center max-w-lg rounded-xl shadow-md space-y-4 p-4 bg-white">
            <h1 className="text-xl md:text-3xl font-semibold uppercase text-center border-b-2 pb-2 border-[#E11756] ">
              Package Details
            </h1>
            <div className="w-full my-4 px-[14px] py-[10px] flex items-center rounded-lg border border-gray-300 space-x-2">
              {/* Search Icon */}
              <BiSolidUser />
              {/* Input field */}
              <input
                type="text"
                className="text-xs md:text-base focus-within:outline-0 focus-visible:outline-0 placeholder:text-[#667085] placeholder:text-base"
                placeholder="Full name"
              />
            </div>
            <div className="w-full my-4 px-[14px] py-[10px] flex items-center rounded-lg border border-gray-300 space-x-2">
              {/* Search Icon */}
              <BiSolidEnvelope />
              {/* Input field */}
              <input
                type="email"
                className="text-xs md:text-base focus-within:outline-0 focus-visible:outline-0 placeholder:text-[#667085] placeholder:text-base"
                placeholder="Email"
              />
            </div>
            <div className="w-full my-4 px-[14px] py-[10px] flex items-center rounded-lg border border-gray-300 space-x-2">
              {/* Input field */}
              <textarea
                type="text"
                rows={5}
                className="w-full text-xs md:text-base focus-within:outline-0 focus-visible:outline-0 placeholder:text-[#667085] placeholder:text-base"
                placeholder="Package Description"
              />
            </div>
            <Link
              to=""
              className="inline-block mt-4"
              style={{ textDecoration: "none" }}
            >
              <div className="button-57 !w-full" role="button">
                <span className="text">Submit</span>
                <span>Submit</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
