import React from "react";
import { styled } from "styled-components";

export default function StepsToCooperation() {
  return (
    <Container>
      <TopContent>
        <h2>
          Steps to <span>Cooperation</span>
        </h2>
        <p>
          At Worldwide Travel Courier, we believe in a collaborative approach
          that brings efficiency and reliability to every shipment.
        </p>
      </TopContent>
      <Steps>
        <Step>
          <figure>
            <img src="/assets/images/package-search.png" alt="" />
            <span>01</span>
          </figure>
          <div>
            <h3>Consultation</h3>
            <p className="desc">
              We begin by understanding your unique requirements. Our expert
              team engages in thorough consultations to grasp the intricacies of
              your cargo, timeline, and specific demands.
            </p>
          </div>
        </Step>
        <Step>
          <figure>
            <img src="/assets/images/cogs.png" alt="" />
            <span>02</span>
          </figure>
          <div>
            <h3>Efficient Execution</h3>
            <p className="desc">
              As your logistics partner, we execute every step meticulously.
              From collection to delivery, our team ensures that your shipments
              are handled with care and precision.
            </p>
          </div>
        </Step>
        <Step>
          <figure>
            <img src="/assets/images/delivery.png" alt="" />
            <span>03</span>
          </figure>
          <div>
            <h3>Timely Delivery</h3>
            <p className="desc">
              Timeliness is at the core of what we do. We prioritize on-time
              deliveries to keep your supply chain running smoothly and your
              customers satisfied.
            </p>
          </div>
        </Step>
      </Steps>
    </Container>
  );
}

const primary = "#E11756";
const secondary = "#FCCC0A";
// const heroText = "#BFC1C2";
const bg = "#fff";
// const dark = "#020";

const Container = styled.div`
  padding: 3rem;

  @media (max-width: 500px) {
    padding: 1.5rem;
    padding: 3rem;
  }
  /* 
  h2 {
    font-family: Poppins;
    color: ${bg};
  } */
`;

const TopContent = styled.div`
  text-align: center;
  h2 {
    font-family: Poppins;
    font-size: 2rem;

    @media (max-width: 500px) {
      font-size: 1.5rem;
      width: 100%;
    }

    span {
      color: ${primary};
      font-family: Poppins;
    }
  }
  p {
    width: 90%;
    max-width: 500px;
    margin: 10px auto;

    @media (max-width: 700px) {
      width: 100%;
    }
  }
`;

const Steps = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Step = styled.div`
  gap: 1rem;
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;

  .desc {
    margin-top: 10px;
  }

  figure {
    padding: 1.5rem;
    position: relative;
    width: fit-content;
    background-color: ${bg};
    border-radius: 50%;
    box-shadow: 0 0 10px 1px #54354d13;
    margin-bottom: 1rem;

    > img {
      max-width: 40px;

      @media (max-width: 900px) {
        max-width: 30px;
      }
    }

    span {
      height: 60px;
      width: 60px;
      position: absolute;
      bottom: 50px;
      right: -30px;
      background: ${primary};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div {
      h3 {
        margin: 1rem;
      }

      p {
        margin-top: 10px;
      }
    }
  }

  &:nth-child(2) {
    figure {
      span {
        background: ${secondary};
      }
    }
  }
`;
