import { styled } from "styled-components";
import { TbStarsFilled } from "react-icons/tb";
import { FaTruckFast } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";

export default function WhyChooseUs() {
  return (
    <Container>
      <Main>
        <h2>
          Why <span>Choose</span> us
        </h2>
        <p>
          We understand that every business has unique needs. That's why we
          offer customized solutions that align with your requirements. From
          specialized handling to personalized delivery schedules, we tailor our
          services to fit your business like a glove.
        </p>
        <Reasons>
          <Reason>
            <figure>
              <FaTruckFast size={25} color={primary} />
            </figure>
            <div>
              <h2>Fast and reliable</h2>
              <p>
                Our well-established network and experienced team ensure that
                your parcels and shipments are delivered promptly and reliably,
                meeting even the tightest deadlines.
              </p>
            </div>
          </Reason>
          <Reason>
            <figure>
              <BiSupport size={25} color={primary} />
            </figure>
            <div>
              <h2>Dedicated Customer Support</h2>
              <p>
                Our dedicated support team is available to address your queries,
                provide updates, and resolve any issues, ensuring a seamless
                experience from start to finish.
              </p>
            </div>
          </Reason>
          <Reason>
            <figure>
              <TbStarsFilled size={25} color={primary} />
            </figure>
            <div>
              <h2>Proven Track Record</h2>
              <p>
                Our long list of satisfied clients attests to our commitment to
                excellence. Join the ranks of businesses that have experienced
                seamless courier transportation with <b>Worldwide Couriers</b>.
              </p>
            </div>
          </Reason>
        </Reasons>
      </Main>
      <Image>
        <figure>
          <img src="/assets/images/container-docker.jpg" alt="" />
        </figure>
      </Image>
    </Container>
  );
}

const primary = "#E11756";
const secondary = "#FCCC0A";
// const heroText = "#BFC1C2";
const bg = "#fff";
// const dark = "#757677";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  background: linear-gradient(#24030d, #4b081db3),
    url("/assets/images/cargo.jpg");
  background-size: cover;
  background-position: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  padding: 5rem 3rem;

  @media (max-width: 500px) {
    padding: 5rem 1.5rem;
  }
`;

const Main = styled.div`
  flex: 1;
  > h2 {
    color: ${bg};
    font-weight: 700;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-family: Poppins;

    @media (max-width: 500px) {
      font-size: 1.5rem;
    }

    span {
      font-family: Poppins;
      color: ${secondary};
    }
  }
  p {
    color: ${bg};
  }
`;

const Reasons = styled.div`
  margin-top: 1rem;
`;
const Reason = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 2.5rem 0;

  figure {
    padding: 1rem;
    /* flex: 0.01; */
    background: ${bg};
    border-radius: 50%;
    width: fit-content;
  }

  div {
    flex: 1;
  }

  h2 {
    color: ${bg};
    font-size: 1.2rem;
    font-weight: 600;
  }

  p {
    color: ${bg};
  }
`;

const Image = styled.div`
  flex: 0.7;
  figure {
    img {
      width: 100%;
    }
  }
`;
