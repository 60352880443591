import React from "react";
import { styled } from "styled-components";
import { BsFillEnvelopeFill, BsTelephoneOutboundFill } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";

export default function ContactDelivery() {
  return (
    <Container>
      <TopContent>
        <h2>
          We provide <span>future</span> of delivery
        </h2>
        <ContactCards>
          <ContactCard className="flex flex-col md:flex-row items-center md:flex-[0.5]">
            <figure>
              <BsTelephoneOutboundFill size={30} />
            </figure>
            <div className="text-center md:text-left">
              <h3 className="font-semibold">Phone</h3>
              <p>+1(318) 485-2037</p>
            </div>
          </ContactCard>
          <ContactCard className="flex flex-col md:flex-row items-center md:flex-[0.5]">
            <figure>
              <FaMapMarkerAlt size={30} />
            </figure>
            <div className="text-center md:text-left">
              <h3 className="font-semibold">Address</h3>
              <p>3075 N COLLEGE AVE, Fayetteville, AR 72703, USA</p>
            </div>
          </ContactCard>
          <ContactCard className="flex flex-col md:flex-row md:flex-[0.5] items-center">
            <figure>
              <BsFillEnvelopeFill size={30} />
            </figure>
            <div className="text-center md:text-left">
              <h3 className="font-semibold">Emails</h3>
              <p>worldwidecourierservice.us@gmail.com</p>
            </div>
          </ContactCard>
        </ContactCards>
      </TopContent>
      <Map>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3223.438089769944!2d-94.14881322455777!3d36.10718337245286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c96bfdd58aad65%3A0xd344e975c47977e4!2s3075%20N%20College%20Ave%2C%20Fayetteville%2C%20AR%2072703%2C%20USA!5e0!3m2!1sen!2sng!4v1691608168942!5m2!1sen!2sng"
          allowFullScreen="true"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="google-maps"
          frameBorder={0}
        ></iframe>
      </Map>
    </Container>
  );
}

const primary = "#E11756";
const secondary = "#FCCC0A";
// const heroText = "#BFC1C2";
// const bg = "#fff";
// const dark = "#020";

const Container = styled.div`
  padding: 3rem;

  @media (max-width: 500px) {
    padding: 1.5rem;
  }
`;

const Map = styled.div`
  iframe {
    width: 100%;
    position: relative;
    z-index: 0;
    margin: 0;
    height: 60vh;
    padding: 0;
  }
`;

const TopContent = styled.div`
  h2 {
    font-family: Poppins;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;

    @media (max-width: 500px) {
      font-size: 1.5rem;
      width: 100%;
    }
    span {
      color: ${primary};
      font-family: Poppins;
    }
  }
`;

const ContactCards = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const ContactCard = styled.div`
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 1rem;
  }

  figure {
    flex: 0.3;
    padding: 1rem;
    background: ${primary};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: 0.9rem;
  }

  div {
    flex: 1;
    padding: 1rem;
  }

  &:nth-child(2) {
    figure {
      background: ${secondary};
    }
  }
`;
